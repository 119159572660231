// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
import TIM from 'tim-js-sdk/tim-js-friendship.js'; //如果您的项目不需要关系链功能，请使用 tim-js-sdk

export let tim = null //im实例
export let timIsLogin = false //登录状态  (true：登录，false：登出)

const SDK_APP_ID = 1400795085  // 即时通信 IM 应用的 SDKAppID

/**
 * 所有监听函数
 */
// SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能
let onSdkReady = function() {
  timIsLogin = true
  tim.on(TIM.EVENT.SDK_NOT_READY, onSdkNotReady);//监听断线
  tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived); //监听消息
  tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated); //监听会话列表更新
  tim.on(TIM.EVENT.FRIEND_LIST_UPDATED, onFriendListUpdated); //监听好友列表更新
};

// SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。
let onSdkNotReady = function() {
  tim.off(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
  tim.off(TIM.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);
  tim.off(TIM.EVENT.FRIEND_LIST_UPDATED, onFriendListUpdated);
  tim.off(TIM.EVENT.SDK_READY, onSdkReady);
  tim.off(TIM.EVENT.SDK_NOT_READY, onSdkNotReady);
};

// SDK 收到推送的单聊、群聊、群提示、群系统通知的新消息，接入侧可通过遍历 event.data 获取消息列表数据并渲染到页面
let onMessageReceived = function(event) {
  // event.data - 存储 Message 对象的数组 - [Message]
  console.log('监听消息-----',event.data);
};

// 会话列表更新，event.data 是包含 Conversation 对象的数组
let onConversationListUpdated = function(event) {
  console.log('会话列表更新-----',event); // 包含 Conversation 实例的数组
};

// 好友列表更新时触发
let onFriendListUpdated = function(event) {
  console.log('好友列表更新-----',event.data);
}

//初始化im实例
export function initIM() {
  let options = {
    SDKAppID: SDK_APP_ID
  };
  // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
  tim = TIM.create(options); // SDK 实例通常用 tim 表示

  // tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
  tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
}

// 登录im
export function loginIM(userID, userSig='eJwtzL0KwjAYheF7ySz1a0xMUnAQHDIUHNpYBxchqXz*hBCjFcR7t5qO5znwvklbN8XTRVIRWgCZ-Tda5xP2mHkB5eR3ezmGgJZUJQMQioPk*XGvgNGNzjmnAJA14e1nSyEoBcXKqYKnMYuqdaGzVMQ4oNZbb9IeQnPeqcdGrjsweJgP*mpq5uWKfL6ykDCC'){
  let promise = tim.login({userID, userSig});
  promise.then(function(imResponse) {
    console.log('登录成功----',imResponse.data); // 登录成功
    tim.on(TIM.EVENT.SDK_READY, onSdkReady); // 监听ready事件
    if (imResponse.data.repeatLogin === true) {
      // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
      console.log(imResponse.data.errorInfo);
    }
  }).catch(function(imError) {
    console.warn('login error:', imError); // 登录失败的相关信息
  });
}

//注销im实例
export function destroyIM(){
  let promise = tim.logout();
  promise.then((imResponse) => {
    console.log('登出成功之后销毁实例-----',imResponse.data); // 登出成功
    tim.destroy(); // 销毁实例
    tim = null
    timIsLogin = false
  }).catch((imError) => {
    console.warn('logout error:', imError);
  });
}

//获取好友列表
export function getFriendList() {
  return tim.getFriendList()
}

// 添加好友
export function addFriend(params) {
  let promise = tim.addFriend({
    to: 'user1',
    source: 'AddSource_Type_Web',
    type: TIM.TYPES.SNS_ADD_TYPE_BOTH,//加好友方式(双向加好友方式)
  });
  promise.then(function(imResponse) {
    // 添加好友的请求发送成功
    const { code } = imResponse.data;
    if (code === 30539) {
      // 30539 说明 user1 设置了【需要经过自己确认对方才能添加自己为好友】，此时 SDK 会触发 TIM.EVENT.FRIEND_APPLICATION_LIST_UPDATED 事件
    } else if (code === 0) {
      // 0 说明 user1 设置了【允许任何人添加自己为好友】，此时 SDK 会触发 TIM.EVENT.FRIEND_LIST_UPDATED 事件
    }
  }).catch(function(imError) {
    console.warn('addFriend error:', imError); // 添加好友失败的相关信息
  });

  return promise
}