import Vue from 'vue';
import axios from 'axios';
import store from '../store/index.js'
import { userBind, getBind } from '../api/api'
// import { Message, Loading } from 'element-ui'
import { Toast, Dialog } from 'vant';

// let BASE_URL = 'http://metanebulas2u.com'; //线上服务器;
// export let WS_API = 'ws://8.218.112.109:234'; //线上服务器;
let BASE_URL = 'https://testhp.mpiswap.cn/'; //测试服务器;
export let WS_API = 'ws://156.236.69.234:234' //测试服务器;
// let HOST = process.env.NODE_ENV;
//判断是生产环境还是开发环境
// switch (HOST) {
//     case 'development':
//         BASE_URL = 'http://localhost:888';
//         break;
//     case 'test':
//         BASE_URL = 'http://192.168.101.21/foxcubeCrm/';
//         break;
//     default:
//         BASE_URL = 'http://192.168.101.21/foxcubeCrm/';
// }


var Axios = axios.create({
     // crossDomain: true,//设置cross跨域
     withCredentials: false,  //跨域请求是否允许携带cookie资源凭证
     // baseurl: BASE_URL,
     time: 1000               //请求超时时间
     // responseType:"arraybuffer"  返回的数据格式
})

Axios.defaults.baseURL = BASE_URL;
// Axios.defaults.baseURL = '/api'
//  request请求拦截器
Axios.interceptors.request.use(config => {
     // console.log('是这样发送请求的嘛----', config)
     // config.url = BASE_URL + config.url
     // let token=localstorage.getItem('token');
     // token && (config.headers.Authorization=token);//请求携带token
     // config.headers = {
     //     'Content-Type': 'application/x-www-form-urlencoded'  //转换数据格式
     // }
     // loadingInstance = Loading.service({
     //     lock: true,
     //     text: '飞速加载中……',
     // });
     // if (config.method === 'post') {
     //     config.data = qs.stringify(config.data)
     // }

     return config;
}, error => {
     return Promise.reject(error);
})

// 成功状态 有3的接口一般是资源重定向
// service.defaults.validateStatus=status=>{
//     return /^(2|3)\d{2}$/.test(status);
// };


// response响应拦截器
Axios.interceptors.response.use(response => {
     // setTimeout(() => {
     //      loadingInstance.close();
     // }, 300)
     if (response.data.code == 500 || response.data.code == 404) {
          Toast(response.data.message);
     }
     if (response.data.code > 40000 && response.data.code < 40600) {
          // Dialog(response.data.msg);
          Dialog.alert({
               message: response.data.message,
               theme: 'round-button',
               confirmButtonText: '确定'
          })
     }
     if (response.data.code == 40600) {
          // Dialog(response.data.msg);
          getBind().then(value => {
               if (value.code == 0) {
                    Dialog.alert({
                         message: response.data.message,
                         theme: 'round-button',
                         confirmButtonText: '确定'
                    }).then(() => {
                         // on close
                         Vue.$greetWindowShow = false //不允许打招呼窗口弹出
                         let toast = Toast.loading({
                              duration: 0, // 持续展示 toast
                              forbidClick: true,
                              message: '绑定上级中...',
                         });
                         console.log('绑定上级的入参----', JSON.parse(localStorage.getItem('userInfo')).parent, store.state.defaultAddress)
                         store.state.toolContract.methods.bind(JSON.parse(localStorage.getItem('userInfo')).parent).send({ from: store.state.defaultAddress }).then(resu => {
                              console.log('绑定成功了嘛-----', resu)
                              Toast('绑定成功');
                              userBind({}, localStorage.getItem('Token')).then(res => {
                                   console.log('更新链上是否已绑定上级----', res)
                                   toast.clear();
                                   Vue.$greetWindowShow = true //允许打招呼窗口弹出
                              })
                         }).catch(err => {
                              Toast('绑定失败');
                              toast.clear();
                              Vue.$greetWindowShow = true //允许打招呼窗口弹出

                         })
                    });
               } else {
                    Dialog.alert({
                         message: value.message,
                         theme: 'round-button',
                         confirmButtonText: '确定'
                    })
               }
          })
     }
     return response;

}, error => {
     // setTimeout(() => {
     //     loadingInstance.close();
     // }, 300)
     let { response } = error;
     console.log('请求是不是失败了', error)
     if (response) {
          //服务器有返回内容
          // var errormsg = '';
          // switch (response.status) {
          //     case 400:
          //         errormsg = '错误请求'
          //         break;
          //     case 401:
          //         errormsg = '未登录,请重新登录'
          //         break;
          //     case 403:
          //         errormsg = '决绝访问'
          //         break;
          //     case 404:
          //         errormsg = '请求错误，未找到该资源'
          //         break;
          //     case 405:
          //         errormsg = '请求方法未允许'
          //         break;
          //     case 408:
          //         errormsg = '请求超时'
          //         break;
          //     case 500:
          //         errormsg = '服务器出错'
          //         break;
          //     case 501:
          //         errormsg = '网络未实现'
          //         break;
          //     case 502:
          //         errormsg = '网络错误'
          //         break;
          //     case 503:
          //         errormsg = '服务不可用'
          //         break;
          //     case 504:
          //         errormsg = '网络超时'
          //         break;
          //     case 505:
          //         errormsg = 'http版本不支持该请求'
          //         break;
          //     default:
          //         errormsg = '连接错误'
          // }
          // // Message({ type: 'warning', message: errormsg });
          // Toast( errormsg );
          return false;
     } else {
          //服务器连结果都没有返回  有可能是断网或者服务器奔溃
          if (!window.navigator.online) {
               //断网处理
               Toast('网络中断');
               return;
          } else {
               //服务器奔了
               Toast('服务器开小差了');
               return Promise.reject(error);
          }
     }
})


/*
*get 方法，对应get请求
*@param {String} url [请求的url地址]
*@param {Object} params[请求携带的参数]]
*/


export function get (url, params, Token) {
     return new Promise((resolve, reject) => {
          Axios.get(url, {
               params: params,
               headers: {
                    'Token': Token
               },
          }).then(res => {
               resolve(res.data);
          }).catch(err => {
               reject(err.data);
          })
     });
}



/*
*post 方法，对应post请求
*@param {String} url [请求的url地址]
*@param {Object} params[请求携带的参数]]
*/


export function post (url, params, Token) {
     return new Promise((resolve, reject) => {
          Axios.post(url, params, {
               headers: {
                    'Token': Token
               },
          })
               .then(res => {
                    resolve(res.data);
               }).catch(err => {
                    reject(err.data);
               })
     });
}

export function uploadImg (url, params) {
     return new Promise((resolve, reject) => {
          Axios.post(url, params, { headers: { 'content-type': 'multipart/form-data' } })
               .then(res => {
                    resolve(res.data);
               }).catch(err => {
                    reject(err.data);
               })
     });
}

