import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './common/lang/lang.js';
import 'vant/lib/index.css';

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

import Web3 from 'web3'
Vue.prototype.Web3 = Web3

import BigNumber from "bignumber.js";
Vue.prototype.$Bignumber = BigNumber

import Clipboard from "clipboard";
Vue.prototype.$Clipboard = Clipboard

import {   
  Search,
  DatetimePicker,
  Popup,
  Circle,
  Field,
  CountDown,
  Cell,
  Calendar,
  List,
  Badge,
  Rate,
  Button,
  Image as VanImage,
  Lazyload,
  Picker,
  Empty,
  Loading,
  PullRefresh,
  Dialog,
  RadioGroup, 
  Radio,
  Stepper,
  Uploader,
  Progress
} from 'vant';


Vue.use(Search)
    .use(DatetimePicker)
    .use(Popup)
    .use(Circle)
    .use(Field)
    .use(CountDown)
    .use(Cell)
    .use(Calendar)
    .use(List)
    .use(PullRefresh)
    .use(Badge)
    .use(Rate)
    .use(Button)
    .use(VanImage)
    .use(Lazyload)
    .use(Empty)
    .use(Picker)
    .use(Loading)
    .use(RadioGroup)
    .use(Radio)
    .use(Stepper)
    .use(Uploader)
    .use(Dialog)
    .use(Progress);

// //移动端调试工具
// import Vconsole from 'vconsole';
// new Vconsole();


    
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
