<template>
  <!-- 手表给建模场景的二级页面项目 -->
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { login, userInfo, userBind } from './api/api'
import { mapMutations, mapState } from 'vuex'
import { initIM, loginIM, destroyIM } from '@/utils/tim.js'
export default {
  data(){
    return {
      superiorCode: '',
      timer: null,
    }
  },
  computed: {
    ...mapState([
      'web3','nftContract','marketContract','toolContract','nftContractAddress','marketContractAddress','solarixAccuracy','solarixAddress','ionzAccuracy','ionzAddress','defaultAddress'
    ]),
  },
  beforeCreate(){
    window.localStorage.removeItem('defaultAddress')
    window.localStorage.removeItem('Token')
    window.localStorage.removeItem('userInfo')
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  created(){
    this.waitForGlobal();
  },
  methods: {
    ...mapMutations([
      'setWeb3',
      'setNftContract',
      'setMarketContract',
      'setDefaultAddress',
      'setToolContract',
      'setPledgeContract',
      'setSolarixContract',
      'setIonzContract',
      'setBusdContract',
      'setIonzBnbPledgeContract',
      'setSolarixBnbPledgeContract',
      'setIonzBnbContract',
      'setSolarixBnbContract',
      'setBitneyContract',
    ]),
    beforeunloadHandler(e) {
      // destroyIM()
    },
    setBind(defaultAddress, parent, Token){
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('信息更新中...'),//信息更新中...
      });
      this.toolContract.methods.getReferrer(defaultAddress).call().then(res => {
        if(res != '0x0000000000000000000000000000000000000000'){
          userBind({}, Token).then(res => {
            console.log('更新链上是否已绑定上级----',res)
            toast.clear();
          }).catch(err => {
            toast.clear();
          })
        }else{
          toast.clear();
        }
      }).catch(err => {
        toast.clear();
      })
    },
    getQueryVariable(variable='invite') {
      var query = window.location.href.substring(1);
      if(window.frameElement){
        query = window.frameElement.baseURI
      }
      console.log('链接url的query----',query);
      var vars = query.split("?");
      for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("/")[0].split("=")
          if(pair[0] == variable){
              this.superiorCode = pair[1]
              console.log('app邀请码是多少----',this.superiorCode);
              return pair[1];
          }
      }
      console.log('app里面的邀请码是多少superiorCode----',this.superiorCode);
      return false
    },
    Login(defaultAddress){
      this.getQueryVariable();
      login({
        invite: this.superiorCode,
        username: defaultAddress,
        password: this.$md5( this.$md5(defaultAddress + 'nft') + 'nft' ),
      }).then(res => {
        console.log('登录了嘛nft----',res)
        if(res.code == 0){
          window.localStorage.setItem('Token', res.data.Token)
          window.localStorage.setItem('defaultAddress', defaultAddress)
          userInfo({}, res.data.Token).then(resu => {
            if(resu.code == 0){
              window.localStorage.setItem('userInfo', JSON.stringify(resu.data) )
              window.localStorage.setItem('langue', resu.data.langue )
              this.$i18n.locale = resu.data.langue
              this.setBind(defaultAddress, resu.data.parent, res.data.Token)
              // initIM()
              // loginIM(resu.data.id)
            }
          })
        }else{
          let toast = this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: res.msg,
          });
        }
      })
    },
    //链接钱包
    waitForGlobal: async function () {
      window.clearTimeout(this.timer);
      // 1. check variable, 检查web3是否已经加载
      let toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '连接钱包中...',
      });
      this.$greetWindowShow = false //不允许打招呼窗口弹出
      var web3Provider = null
      if (window.ethereum) {
          web3Provider = window.ethereum;
          console.log('window.ethereum-------',window.ethereum)
          try {
            // 请求用户受权
            // await window.ethereum.enable();
            await window.ethereum.enable();
          } catch (error) {
            // 用户不受权时
            console.error("User denied account access")
          }
      } else if (window.web3) {   // 老版 MetaMask Legacy dapp browsers...
        web3Provider = window.web3.currentProvider;
        console.log('window.web3.currentProvider-------',window.web3.currentProvider)
      } else if (window.parent.ethereum) {   // 在iframe中，要获取父级的window对象
        web3Provider = window.parent.ethereum;
        console.log('window.parent.ethereum-------',window.parent.ethereum)
      } else {
        web3Provider = new this.Web3.providers.HttpProvider('http://localhost:8545');
        this.timer = setTimeout(
          this.waitForGlobal
        ,100);
        return;
      }
      let web3 = new this.Web3(web3Provider);

      this.setWeb3(web3)
      this.setMarketContract(web3)
      this.setNftContract(web3)
      this.setToolContract(web3)
      this.setPledgeContract(web3)
      this.setSolarixContract(web3)
      this.setIonzContract(web3)
      this.setBusdContract(web3)
      this.setIonzBnbPledgeContract(web3)
      this.setSolarixBnbPledgeContract(web3)
      this.setIonzBnbContract(web3)
      this.setSolarixBnbContract(web3)
      this.setBitneyContract(web3)
      
      web3.eth.getAccounts( (error, result) => {
        if (!error){
            //受权成功后result能正常获取到帐号了
            this.setDefaultAddress(result[0])
            if( localStorage.getItem('defaultAddress') != result[0] ){
              this.Login(result[0])
            }
            toast.clear();
            window.clearTimeout(this.timer);
        }
      });
    },
  }
}
</script>

<style lang="less">
@import "./assets/css/font.css";
body {
  margin: 0px;
}
div{
  box-sizing: border-box;
}
.pointer{
  cursor: pointer;
}

@media screen and (min-width: 480px){
  //在这里写非小屏幕设备的样式  -- 非手机
  body {
    margin: auto !important;
    max-width: 430px;
    // max-height: 700px;
    // overflow-y: scroll;

    scrollbar-width: none;/* Firefox */
    -ms-overflow-style: none;/* IE 10 */
  }
  body::-webkit-scrollbar{
    display: none;
  }
  #app{
    // max-height: 700px;
    // overflow-y: scroll;

    scrollbar-width: none;/* Firefox */
    -ms-overflow-style: none;/* IE 10 */
  }
  #app::-webkit-scrollbar{
    display: none;
  }
}
.vn-italic{
    font-family: 'vn-italic' !important;
  }
  .vn-bold-italic{
    font-family: 'vn-bold-italic' !important;
  }
</style>
