/* 韩文 */
export default {
     '一起社交赚钱': '함께 가자 ! 부자의 길로!',
     '重连...': '재 연결중…',
     '收件箱': '수신함',
     '聊天': '채팅',
     '消息/聊天': '메세지/채팅',
     '消息 - 未读': '메세지 - 읽지 않음',
     '没有更多了': '더 이상 없음',
     '暂无昵称': '닉네임 없음',
     '排行': '랭킹',
     '在此输入...': '입력중…',
     '连接中...': '연결중…',
     '发送消息不能为空': '메시지를 비워둘 수 없습니다.',
     '连接钱包': '지갑 연결',
     '选择要连接的钱包': '연결할 지갑 선택',
     '钱包': '지갑 ',
     '好友': '친구',
     '朋友': '친구',
     '加载中...': '로딩중…',
     '添加好友': '친구 추가',
     '请输入搜索关键词': '검색어를 입력해주세요',
     '推荐玩家': '플레이어 추천',
     '刷新': '새로 고침',
     '请求': '요구 사항',
     '好友请求': '친구 요청',
     '积分': '포인트',
     'NFT收藏': 'NFT 저장',
     '探索距离': '거리 수색',
     '步数': '스탭',
     '心率': '심장 BPM',
     '心率差': '심박수 차이',
     '卡路里': '칼로리',
     '时长': '기간',
     '耐用度': '내구성',
     '开始运动': '운동 시작',
     '结束运动': '운동 종료',
     '恢复耐久': '내구성 복구',
     '日期预设': '설정 날짜',
     '月': '월',
     '日': '일',
     '户外跑步总记录': '야외 런닝 기록',
     '查看活动报告': '활동보고 보기',
     '交易市场': '마케팅 시장',
     '社交挖矿': '소셜 채굴',
     '选择年月': '년,월 선택',
     '修复中...': '회복중...',
     '修复成功': '회복 성공',
     '修复失败': '회복 실패',
     '市场': '마케팅 시장',
     '探索': '시장',
     '热门': '인기',
     '拍卖': '경매',
     '总共': '합계',
     '暂无': '없음',
     '购买': '구매',
     '授权': '수권',
     '余额不足': '잔액 부족',
     '购买中...': '구매중...',
     '购买成功': '구매 성공',
     '购买失败': '구매 실패',
     '授权中...': '수권중...',
     '授权成功': '수권 성공',
     '授权失败': '수권 실패',
     '我的NFT收藏': '내 NFT',
     'NFT角色信息': 'NFT 캐릭터 정보',
     '取消挂卖': '판매 취소',
     '挂卖': '판매',
     '升级中...': '업그레이드 중...',
     '升级': '레벨 업',
     '修复耐久性': '내구성 회복',
     '信息': '메세지',
     '成长': '업그레이드',
     '最高等级': '최고레벨',
     '请输入挂卖价格': '판매 가격을 입력해 주세요',
     '确定': '확인',
     '取消': '취소',
     '价格': '가격',
     '请输入价格': '가격을 입력해 주세요',
     '取消中...': '취소중...',
     '取消成功': '취소 성공',
     '取消失败': '취소 실패',
     '价格需要大于0': '0보다 큰 가격 필요',
     '挂卖中...': '판매 중...',
     '挂卖成功': '판매 성공',
     '挂卖失败': '판매 실패',
     '这张卡片不需要修复': '이 NFT는 회복할 필요가 없습니다.',
     '修复成功,等待交易查询中': '회복 성공, 거래 대기 중',
     '升级失败': '업그레이드 실패',
     '我的简历': '내 프로필',
     '地址': '주소',
     '我的钱包余额': '나의 지갑 잔액',
     '请输入口令': '비밀번호를 입력해 주세요',
     '当地语言': '현지 언어',
     '评分列表': '평점 리스트',
     '购买评分': '평점 구매',
     '密码不能为空': ' 비밀번호는 비워둘 수 없습니다.',
     '复制成功': '복사 성공',
     '不支持复制': '복사를 지원하지 않음',
     '购买成功,等待交易查询中': '구매 성공, 거래 대기 중',
     '主菜单': '메인 메뉴',
     '首页': '메인 페이지',
     '任务': '미션',
     '排行榜': '랭킹',
     '开启运动模式': '운동 모드 켜기',
     '开启社交模式': '소셜 모드 켜기',
     '聊天室': '채팅방',
     '个人简介': '개인 프로필',
     '报告': '보고',
     '时间': '시간',
     '前三名玩家': '탑 3 플레이어',
     '暂无排名': '아직 랭킹순위 없음',
     '玩家排行': '플레이어 랭킹',
     '选择年月日': '년,월,일 선택',
     '运动': '운동 ',
     '社交': '소셜 ',
     '总分': '합계 포인트',
     '持续': '지속',
     '玩家': '플레이어',
     '等级': '레벨',
     '成本': '비용',
     '开始时间': '시작 시간',
     '至': '까지',
     '结束时间': '종료 시간',
     '收益': '소득',
     '我的收益报告': '내 수익 보고',
     '奖励': '보상',
     '我的奖励报告': '나의 보상 보고',
     '我的任务报告': '나의 미션 보고',
     '我的NFT报告': '나의 NFT 보고',
     '开始时间要小于结束时间': '시작 시간은 종료 시간보다 작아야 합니다.',
     '结束时间要大于开始时间': '종료 시간은 시작 시간보다 커야 합니다.',
     '社交聊天': '쇼셜 채팅',
     '聊天室名称': '채팅방 명칭',
     '人数': '인원수',
     '创建': '만들기',
     '房间名不能为空': '방 명칭은 비워둘수 없습니다',
     '聊天室邀请': '채팅방 초대',
     '邀请': '초대',
     '暂无名称': '명칭이 없습니다',
     '距离': '거리 ',
     '准备': '준비',
     '邀请朋友': '친구 초대',
     '米': '미터',
     '暂无房间id': '방 ID가 없습니다',
     '我的聊天室': '나의 채팅방',
     '购买钥匙': '키 구매',
     '准备中': '준비중',
     '进行中': '진행중',
     '待评价': '평가 대기',
     '待结算': '결산 대기',
     '已结束': '종료',
     '暂未创建或加入聊天室': '아직 생성되거나 가입된 소셜 방이 없습니다',
     '推荐聊天室': '채팅방 추천',
     '头像': '프로필 사진',
     '这个聊天室没有名称': '이 소셜방에는 이름이 없습니다.',
     '申请': '신청',
     '已加入聊天室': '채팅방 가입 완료',
     '等待其他玩家评价': '플레이어 평가 대기중',
     '您还没有创建房间': '방을 만들지 않았습니다.',
     '开始挖矿': '채굴 시작',
     '退出房间': '채팅방 퇴장',
     '复制链接': '링크 복사',
     '发送邀请': '초대 보내기',
     '提示': '알림',
     '请确认是否退出聊天室': '소셜 방을 나가려면 확인하십시오.',
     '请输入邀请链接': '초대 링크를 입력하십시오',
     '加入聊天室': '채팅방 가입',
     '在挖矿中': '채굴 중',
     '定时进程': '카운트다운',
     '完成挖矿': '채굴 완성',
     '停止聊天室': '소셜 방 중지',
     '在线玩家': '온라인 플레이어',
     '请确认是否停止聊天，此操作将会退出聊天室并且不会获得收益': '소셜 방을 중지 하시겠습니까?  소셜 방을 퇴장하면 보상을 받지 못합니다.',
     '社交聊天室': '소셜 채팅방',
     '创建聊天室': '채팅방 만들기',
     '邀请链接': '초대 링크',
     '有新的请求，请在请求弹窗中查看': '새 요청이 있습니다. 요청 팝업을 체크 하십시오',
     '有新的邀请，请在邀请弹窗中查看': '새 초대장이 있습니다. 초대 팝업을 체크 하십시오.',
     '房主已拒绝': '방 소유자가 귀하의 요청을 거부했습니다',
     '用户拒绝加入': '플레이어가 초대를 거부합니다',
     '挖矿收益': '채굴 수익',
     '我的评分': '나의 평점',
     '奖励分配': '보상분배',
     '评分': '평점',
     '社交评分': '소셜 평점',
     '提交': '제출',
     "暂无计划":"아직 계획이 없습니다.",
     "活动报告":"활동 보고",
     "分钟":"분",
     "每日":"매일",
     "每月":"매월",
     "获得":"획득",
     "总支出":"총 지출",
     "点 击 关 闭":"닫기 클릭",
     "朋友帮助请求":"친구 도움말 요청",
     "发送帮助":"도움말 보내기",
     "推荐任务":"추천 미션",
     "描述":"설명",
     "接受":"접수",
     "待完成":"완료 대기",
     "特别活动":"스페셜 활동",
     "特别":"스페셜",
     '此操作会将房间解散，并且不会退还钥匙': '이 작업을 수행하면 방이 해체되고 키가 반환되지 않습니다.',
     '运动收益': '스포츠 리워드',
     '社交收益': '사회적 보상',
     '累计时长(分钟)': '누적 시간(분)',
     '实时运动': '시간제 노동자',
     '领取': '주장하다',
     '交易成功后等级 + 1': '거래 성공 후 레벨 + 1',
     '信息更新中...': '정보 업데이트 중...',
     '连接钱包中...': '지갑 연결 중...',
     '解散房间': '해산 룸',
     '未结算': '콩 엔 엥 엔',
     '已结算': '안정된',
     '日期选择': '날짜 선택',
     '未领取': '받지 못했다',
     '已领取': '청구됨',
     '已升级的nft数量': '업그레이드된 NFT 수량',
     '请选择修复次数':'수리 횟수를 선택하세요.',
     '暂无nft可领取':'수집할 수 있는 NFT 없음',
     '暂无团队收益可领取':'현재 받을 수 있는 팀 보상이 없습니다.',
     '团队收益':'팀 보상',
     '当前修复分数': '현재 수리 내구성',
     '请输入密码': '비밀번호를 입력 해주세요',
     '设置密码': '암호를 설정하세요',
     '密码': '비밀번호',
     '再次确认': '비밀번호 확인',
     '请再次输入密码': '비밀번호를 다시 입력하세요',
     '两次输入的密码不一致': '비밀번호가 일치하지 않습니다',
     '未激活': '활성화되지 않은',
     '已激活': '활성화됨',
     '成功': '성공적인',
     '失败': '실패한',
     '请确认是否将此用户请离房间': '방에서 이 사용자를 제거하려면 확인하십시오.',
     '收藏奖励': '수집 보상',
     '教程': '지도 시간',
     '请选择类型': '유형 선택',
     '评分(人气)': '평가',
     '日榜': '일일 목록',
     '月榜': '월간 차트',
     '总榜': '총 목록',
     '升级进度': '업그레이드 진행',
     '暂无收益可领取': '수령 가능한 수익이 잠시 없습니다',
     '价格需要大于5000': '5000보다 큰 가격 필요',
     '团队NFT升级奖励': 'NFT 업그레이드 수입',
     '名称': '이름',
     '钱包地址': '지갑 주소',
     '设置昵称': '닉네임 설정',
     '昵称': '닉네임',
     '请输入昵称': '닉네임을 입력하십시오.',
     '昵称不能为空': '닉네임은 비워둘 수 없습니다.',
     '请先选择头像': '먼저 프로필 사진을 선택하십시오.',
     '设置个人信息': '개인 정보 설정',
     '数量': '수량',
     '选择钥匙数量': '열쇠 수량 선택',
}