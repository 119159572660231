import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import cn from './cn'
import en from './en'
import vn from './vn'
import kr from './kr'
import id from './id'
import my from './my'
let locale = localStorage.getItem('langue') || '';
if( locale == '' ){
	locale = 'cn';
	localStorage.setItem('langue',locale)
}
const i18n = new VueI18n({
	locale: locale,
	messages: {
		cn,
		en,
		vn,
		kr,
          id,
          my,
	},
	fallbackLocale: 'cn',
	formatFallbackMessages: true
})
export default i18n