import { post, get, uploadImg } from '../utils/reques'



export const login = params => post('/api/user/login', params)
export const userInfo = (params, Token) => get('/api/user/info', params, Token)
export const userBind = (params, Token) => post('/api/user/bind', params, Token)
export const updateInfo = params => post('/api/user/info', params, localStorage.getItem('Token'))//更新用户信息
export const mainConfig = params => get('/api/main/config', params)// 获取配置信息
export const userParent = params => post('/api/user/parent', params, localStorage.getItem('Token'))// 购买前测试绑定上级没
export const getBind = params => get('/api/user/bind', params, localStorage.getItem('Token'))// 判断能否操作绑定上级动作

export const language = params => post('/api/user/setlanguage', params, localStorage.getItem('Token'))// 设置语言
export const uploadApi = params => post('api/main/upload', params, localStorage.getItem('Token'))// 上传


//nft
export const getMyNftList = params => get('/api/user/NftList', params, localStorage.getItem('Token'))// 获取我的nft列表 
export const nftSwitch = params => get('/api/user/Switch', params, localStorage.getItem('Token'))// 首页切换nft
export const nftRepair = params => get('/api/user/Repair', params, localStorage.getItem('Token'))// 修复nft
export const nftUpgrade = params => post('/api/user/Upgrade', params, localStorage.getItem('Token'))// 升级nft
export const nftSportState = params => get('/api/user/NftState', params, localStorage.getItem('Token'))// nft运动状态 
export const nftBuy = params => post('/api/user/BuyNtf', params, localStorage.getItem('Token'))// 购买nft
export const nftOrderBefore = params => post('/api/user/OrderBefore', params, localStorage.getItem('Token'))// 挂卖或取消挂卖之前
export const nftCancel = params => post('/api/user/Cancel', params, localStorage.getItem('Token'))// 取消挂卖之前
export const nftHangingSale = params => post('/api/user/HangingSale', params, localStorage.getItem('Token'))// nft挂卖


//运动挖矿接口
export const getMotionStatistics = params => get('/api/user/MotionStatistics', params, localStorage.getItem('Token'))// 获取步数心率卡路里历史数据 (折线图)
export const getWatchInfo = params => get('/api/user/WatchInfo', params, localStorage.getItem('Token'))// 运动手表信息 (首页数据)
export const startSport = params => get('/api/user/StartMotion', params, localStorage.getItem('Token'))// 首页开始运动  
export const getPercentage = params => get('/api/user/Percentage', params, localStorage.getItem('Token'))// 步数心率卡路里 (环形图百分比) 
export const getDateList = params => get('/api/user/DateList', params, localStorage.getItem('Token'))// 查询日期预设信息(首页)
export const getDateInfo = params => get('/api/user/DateInfo', params, localStorage.getItem('Token'))// 查询日期预设详情(活动报告页面)
export const sportEnd = params => get('/api/user/EndMarket', params, localStorage.getItem('Token'))// 结束运动
export const Sportdata = params => get('/api/user/Sportdata', params, localStorage.getItem('Token'))// 当次运动数据



//好友模块接口
export const getFriendsList = params => get('/api/user/Friends', params, localStorage.getItem('Token'))// 好友排行榜(我的好友列表)
export const getRecommend = params => get('/api/user/Recommend', params, localStorage.getItem('Token'))// 推荐好友列表
export const getFriendResList = params => get('/api/user/FriendsRequest', params, localStorage.getItem('Token'))// 请求列表
export const handleRequest = params => post('/api/user/FriendsRequestType', params, localStorage.getItem('Token'))// 请求处理 (同意|拒绝)
export const addFriendsRequest = params => get('/api/user/AddFriendsRequest', params, localStorage.getItem('Token'))// 添加请求
export const FriendsnumApi = params => get('/api/user/Friendsnum', params, localStorage.getItem('Token'))// 信息数量统计


// 社交挖矿
export const AddChatApi = params => post('/api/user/AddChat', params, localStorage.getItem('Token'))// 创建房间
export const AddRoomRequestApi = params => post('/api/user/AddRoomRequest', params, localStorage.getItem('Token'))// 邀请加入
export const AddRoomApi = params => post('/api/user/AddRoom', params, localStorage.getItem('Token'))// 同意/拒绝加入房间
export const RecommendChatApi = params => get('/api/user/RecommendChat', params, localStorage.getItem('Token'))// 推荐聊天室
export const OutApi = params => post('/api/user/Out', params, localStorage.getItem('Token'))// 退出房间
export const ChatInfoApi = params => get('/api/user/ChatInfo', params, localStorage.getItem('Token'))// 房间信息
export const ChatListApi = params => get('/api/user/ChatList', params, localStorage.getItem('Token'))// 请求列表
export const InvitationApi = params => get('/api/user/Invitation', params, localStorage.getItem('Token'))// 邀请列表
export const StartMiningApi = params => post('/api/user/StartMining', params, localStorage.getItem('Token'))// 开始挖矿
export const ConfgScoreApi = params => get('/api/user/ConfgScore', params, localStorage.getItem('Token'))// 评分配置
export const partyEvaluateApi = params => post('/api/user/partyEvaluate', params, localStorage.getItem('Token'))// 评分
export const ApplyApi = params => post('/api/user/Apply', params, localStorage.getItem('Token'))// 申请加入房间
export const EvaluateListApi = params => get('/api/user/EvaluateList', params, localStorage.getItem('Token'))// 房间评分列表
export const AddUrlRoomApi = params => post('/api/user/AddUrlRoom', params, localStorage.getItem('Token'))// 链接加入
export const StartScoreApi = params => post('/api/user/StartScore', params, localStorage.getItem('Token'))// 开始评分(类似于停止挖矿)
export const ScoredListApi = params => get('/api/user/ScoredList', params, localStorage.getItem('Token'))// 被评分列表
export const ScoredInfoApi = params => get('/api/user/ScoredInfo', params, localStorage.getItem('Token'))// 被评分信息
export const ChatOperationApi = params => post('/api/user/ChatOperation', params, localStorage.getItem('Token'))// 同意/拒绝请求
export const BuyIntegralApi = params => get('/api/user/BuyIntegral', params, localStorage.getItem('Token'))// 购买评分
export const BuyKeyApi = params => get('/api/user/BuyKey', params, localStorage.getItem('Token'))// 购买钥匙
export const DelChatApi = params => post('/api/user/DelChat', params, localStorage.getItem('Token'))// 同意/拒绝请求


//排行榜
export const getRankingList = params => get('/api/ranking/index', params, localStorage.getItem('Token'))// 排行榜
export const getBannerList = params => get('/api/banner/list', params, localStorage.getItem('Token'))// 获取排行榜轮播图


//报告
export const getUpgradeList = params => get('/api/upgrade/index', params, localStorage.getItem('Token'))// 报告


//任务 
export const getTasknewList = params => get('/api/tasknew/index', params, localStorage.getItem('Token'))// 任务
export const TaskReceiveApi = params => post('/api/user/TaskReceive', params, localStorage.getItem('Token'))// 领取任务

/* 聊天 */
export const MsgListApi = params => get('/api/user/MsgList', params, localStorage.getItem('Token'))// 聊天列表
export const HistoricalDataApi = params => get('/api/user/HistoricalData', params, localStorage.getItem('Token'))// 聊天记录
export const sendMsgApi = params => post('/api/user/sendMsg', params, localStorage.getItem('Token'))// 发消息

// 市场
export const MarketplaceApi = params => get('/api/user/Marketplace', params, localStorage.getItem('Token'))// 市场列表 type 0 探索 1热门 2拍卖

//领取收益
export const sendApi = params => post('/api/user/Send', params, localStorage.getItem('Token'))// 领取收益(运动/社交)
export const moneyTotalApi = params => post('/api/user/MoneyTotal', params, localStorage.getItem('Token'))// 查询可领取收益数量
export const receiveApi = params => post('/api/user/receive', params, localStorage.getItem('Token'))// 领取团队收益


// 获取当前服务器时间 
export const systemTimeApi = params => get('/api/system/time', params, localStorage.getItem('Token'))// 当前服务器时间


//meta2.0所需要的秘钥接口
export const getEncryCode = params => get('/api/main/code', params, localStorage.getItem('Token'))//获取加密随机串
export const setWatchPassword = params => post('/api/user/watchpassword', params, localStorage.getItem('Token'))//设置手表的口令


//nft升级新增接口 
export const getIsMarket = params => get('/api/user/IsMarket', params, localStorage.getItem('Token'))//可领取的nft(升级后)
export const receiveNft = params => post('/api/user/ReceiveNft', params, localStorage.getItem('Token'))//领取升级的nft


//团队NFT升级奖励
export const NFTUpgradeTeamAwardReceiveTotal = params => get('/api/receiveAward/NFTUpgradeTeamAwardReceiveTotal', params, localStorage.getItem('Token'))//可领取总数
export const NFTUpgradeTeamAwardReceive = params => post('/api/receiveAward/NFTUpgradeTeamAwardReceive', params, localStorage.getItem('Token'))//领取
