import Vue from 'vue'
import Vuex from 'vuex'
import { marketAbi, nftAbi, erc20Abi, toolAbi, pledgeAbi, pledgeLpAbi } from '../utils/abi'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ionzBnbAddress: '0xe92526804f2B1560Cd10E5B626CfA8fd64c793F9',
    // ionzBnbAddress: '0x2180179A1309132873e750F8c7dfBc5173966B9A',
    ionzBnbAccuracy: 18,
    solarixBnbAddress: '0x0e7e4BE1E90FEF9688A6099EC4C61CcaA00E0b23',
    // solarixBnbAddress: '0xCe372Ff7F8416eD79A06005f78e7fec6C12C9C95',
    solarixBnbAccuracy: 18,
    ionzBnbContract: null,
    solarixBnbContract: null,

    bnyAddress: '0xE41CCF516EcB31c4d106d0cEBE12Bb751Ce8C57a',
    bnyAccuracy: 10,//bny精度
    ionzAddress: '0x7268192a0e5882b21F13Fc857cF78299D8e3D75b',//正式
    // ionzAddress: '0x36BE08939D6A98Ca427f85e9341DEE99C414eEcB',//二期测试币
    // ionzAddress: '0x19C04401f96648C73ad8Ed78b4dDd4cde6C377b1',//三期测试币
    ionzAccuracy: 6,//IONZ精度
    solarixAddress: '0xfdcE54744801c9Eb88D8445673Ad267F8d43a9eE',//正式
    // solarixAddress: '0x486677715f3DFD41B751c258727Dc37B60853432',
    solarixAccuracy: 6,//solarix精度
    busdAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    busdAccuracy: 18,//BUSD精度
    bitneyAddress: '0x66e29c9dea509640a006c173632c5d5be6cabc93',//正式币  
    bitneyAccuracy: 18,//测试精度
    defaultAddress: '正在链接钱包中...',
    web3: null,
    nftContract: null,
    marketContract: null,
    toolContract: null,
    pledgeContract: null,

    pledgeIonzBnbContract: null,
    pledgeSolarixBnbContract: null,

    solarixContract: null,
    ionzContract: null,
    busdContract: null,
    bitneyContract: null,

    AtokenContract: null,//测试网的假合约
    AtokenAddress: '0x36BE08939D6A98Ca427f85e9341DEE99C414eEcB',//测试网地址

    toolContractAddress: '0x77DEcb56b3B65ad92144dBEbeaE25Db8b407756c',//正式
    // toolContractAddress: '0xa966c2Ad23c96fDecAcAD21b26A91548c8Fc8044',
    nftContractAddress: '0x3D118029b637C3D18e82dbC72F18b06AFce887Fe',//正式
    // nftContractAddress: '0x8861a0b334C61768323B055589cbfa1D0C258550',
    marketContractAddress: '0xAAD3954D438D0b8d9f57b62842efEBe2F5647fa6',//正式
    // marketContractAddress: '0x44eF8748235C2bC498D754823C463eE243B27e34',
    pledgeContractAddress: '0x8f11fa5aC777a4C47E3D495bbC93C7d4be7fb6ff',//正式质押合约地址
    // pledgeContractAddress: '0x5674a6E753165db9685c385cFdbc22b656142Cc4',
    
    pledgeIonzBnbContractAddress: '0x5ab0D16579BA74A0c53C1A694E61945B2CC4bB69',
    pledgeSolarixBnbContractAddress: '0x8338f64d30869C7544b67f7A568Ca2d03b7ff47f',
    usdtAccuracy: 18,
  },
  mutations: {
    setDefaultAddress (state, address) {
      state.defaultAddress = address
    },
    setWeb3 (state, web3) {
      state.web3 = web3
    },
    setNftContract (state, web3) {
      state.nftContract = new web3.eth.Contract(nftAbi, state.nftContractAddress);
    },
    setPledgeContract (state, web3) {
      state.pledgeContract = new web3.eth.Contract(pledgeAbi, state.pledgeContractAddress);
    },

    setIonzBnbPledgeContract (state, web3) {
      state.pledgeIonzBnbContract = new web3.eth.Contract(pledgeLpAbi, state.pledgeIonzBnbContractAddress);
    },
    setSolarixBnbPledgeContract (state, web3) {
      state.pledgeSolarixBnbContract = new web3.eth.Contract(pledgeLpAbi, state.pledgeSolarixBnbContractAddress);
    },


    setMarketContract (state, web3) {
      state.marketContract = new web3.eth.Contract(marketAbi, state.marketContractAddress);
    },
    setToolContract (state, web3) {
      state.toolContract = new web3.eth.Contract(toolAbi, state.toolContractAddress);
    },
    setSolarixContract (state, web3) {
      state.solarixContract = new web3.eth.Contract(erc20Abi, state.solarixAddress);
    },
    setIonzContract (state, web3) {
      state.ionzContract = new web3.eth.Contract(erc20Abi, state.ionzAddress);
    },
    setBusdContract (state, web3) {
      state.busdContract = new web3.eth.Contract(erc20Abi, state.busdAddress);
    },
    setBitneyContract (state, web3) {
     state.bitneyContract = new web3.eth.Contract(erc20Abi, state.bitneyAddress);
   },

    setIonzBnbContract (state, web3) {
      state.ionzBnbContract = new web3.eth.Contract(erc20Abi, state.ionzBnbAddress);
    },
    setSolarixBnbContract (state, web3) {
      state.solarixBnbContract = new web3.eth.Contract(erc20Abi, state.solarixBnbAddress);
    },


    setAtokenContract (state, web3) {//仅用于测试网
      state.AtokenContract = new web3.eth.Contract(erc20Abi, state.AtokenAddress);
    }
  },
  actions: {

  }
})
