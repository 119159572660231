import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//重复点击相同路由报错解决
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

const routes = [
  //手环模块路由
  {
    path: '/',
    name: 'braceletHome',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/friendAdd',
    name: 'friendAdd',
    component: () => import('../views/friend/friendAdd/index.vue')
  },
  {
    path: '/friendRequest',
    name: 'friendRequest',
    component: () => import('../views/friend/friendRequest/index.vue')
  },
  {
    path: '/friend',
    name: 'friend',
    component: () => import('../views/friend/friend/index.vue')
  },
  {
    path: '/calorie',
    name: 'calorie',
    component: () => import('../views/sport/calorie/index.vue')
  },
  {
    path: '/heartRate',
    name: 'heartRate',
    component: () => import('../views/sport/heartRate/index.vue')
  },
  {
    path: '/stepsNumber',
    name: 'stepsNumber',
    component: () => import('../views/sport/stepsNumber/index.vue')
  },
  {
    path: '/activityReport',
    name: 'activityReport',
    component: () => import('../views/sport/activityReport/index.vue')
  },
  {
    path: '/connectWallet',
    name: 'connectWallet',
    component: () => import('../views/connectWallet/index.vue')
  },
  {
    path: '/roomHome',
    name: 'roomHome',
    component: () => import('../views/room/home/index.vue')
  },
  {
    path: '/roomScore',
    name: 'roomScore',
    component: () => import('../views/room/score/index.vue')
  },
  {
    path: '/myRating',
    name: 'myRating',
    component: () => import('../views/room/myRating/index.vue')
  },
  {
    path: '/ratingList',
    name: 'ratingList',
    component: () => import('../views/room/ratingList/index.vue')
  },
  {
    path: '/successUpgraded',
    name: 'successUpgraded',
    component: () => import('../views/successUpgraded/index.vue')
  },
  {
    path: '/myNFT',
    name: 'myNFT',
    component: () => import('../views/myNFT/index.vue')
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('../views/market/index.vue')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../views/report/index.vue')
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import('../views/ranking/index.vue')
  },
  {
    path: '/myProfile',
    name: 'myProfile',
    component: () => import('../views/myProfile/index.vue')
  },
  {
    path: '/task',
    name: 'task',
    component: () => import('../views/task/index.vue')
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import('../views/chat/inbox/index.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/chat/message/index.vue')
  },
  {
     path: '/navbar',
     name: 'navbar',
     component: () => import('../views/navBar/index.vue')
   }
]

const router = new VueRouter({
  routes
})

export default router
